<template>
    <ModalWrapper ref="modal" custom-close transparent @overlay-click="close" @close-modal="close">
        <div class="modal-window abs-user-modal">
            <div class="modal-window__close" @click="closeModal">
                <AppIcon icon="close"/>
            </div>
            <div class="modal-window__header">
                <AppIcon icon="user"/>
                {{ $t("components.common.customerCardInAbs") }}
            </div>
            <div class="modal-window__body">
                <div v-if="isError" class="abs-error mb-13-a">
                    <img src="../../assets/img/abs-customer-error.svg" alt="" class="abs-error__image">
                    <div class="abs-error__name">{{ $t("components.common.oopsSomethingWentWrong") }}</div>
                    <div class="abs-error__description">{{ $t("components.common.errorLoadingData") }}</div>
                </div>
                <LoadingContent v-else :loading="isLoading" class="mb-9-a">
                    <div class="abs-user-data">
                        <template v-for="(item, idx) in data">
                            <div :key="item.name + idx" class="abs-user-data__item">
                                <div class="abs-user-data__name">{{ $t('components.abs-modal.' + item.name) }}</div>
                                <div class="abs-user-data__value">{{ $te(item.value) ? $t(item.value) : item.value }}</div>
                            </div>
                            <hr v-if="idx === 2" :key="item.name" class="abs-user-data__separator">
                        </template>
                    </div>
                </LoadingContent>
                <AppButton class="w-100" :theme="isError ? 'danger-light' : ''" type="button" @click="closeModal">
                    {{ $t("components.common.itIsClear") }}
                </AppButton>
            </div>
        </div>
    </ModalWrapper>
</template>
<script>
import AppIcon from "../partials/AppIcon";
import AppButton from "../partials/AppButton";
import ModalWrapper from "../partials/ModalWrapper";
import {mapState} from "vuex";
import LoadingContent from "../partials/LoadingContent";
import ABSCustomer from "../../services/customer/ABSCustomer";

export default {
    name: 'ABSCustomerModal',
    components: {LoadingContent, ModalWrapper, AppButton, AppIcon},
    data() {
        return {
            isLoading: false,
            data: null,
            isError: false
        }
    },
    computed: {
        ...mapState('customer', ['activeAbsCustomer']),
    },
    mounted() {
        this.isLoading = true;
        ABSCustomer.getData(this.activeAbsCustomer)
            .then(data => {
                this.data = data;
            })
            .catch(e => {
                this.isError = true;
            })
            .finally(() => {
                this.isLoading = false;
            })
    },
    beforeDestroy() {
        this.$store.commit('customer/selectAbsCustomer', null);
    },
    methods: {
        close() {
            this.$emit('close-modal');
        },
        closeModal() {
            this.$refs.modal.close();
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.abs-user-modal {
    width: 702px;
    &__empty-message {
        margin-top: -38px;
        margin-left: -42px;
    }
}
.abs-error {
    text-align: center;
    font-family: $font-secondary;
    line-height: 20px;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    &__image {
        width: 128px;
        height: 128px;
        display: block;
        margin: 0 auto 24px;
    }
    &__name {
        margin-bottom: 2px;
        font-weight: 500;
        font-size: 14px;
        color: #717171;
    }
    &__description {
        font-weight: 700;
        font-size: 16px;
        color: #3C3C3C;
    }
}
.abs-user-data {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    font-family: $font-secondary;
    margin-left: -16px;
    &__item {
        width: calc(100% / 3 - 16px);
        margin-bottom: 18px;
        margin-left: 16px;
        &:nth-child(-n+3) {
            text-transform: uppercase;
            #{$self}__value {
                font-weight: 700;
            }
        }
    }
    &__separator {
        margin-top: 0;
        margin-bottom: 18px;
        background-color: var(--color-primary);
        border: 0;
        height: 1px;
        width: 100%;
    }
    &__name {
        margin-bottom: 2px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #717171;
    }
    &__value {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #3C3C3C;
        word-break: break-word;
        hyphens: auto;
    }
}
</style>
